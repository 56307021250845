import { setToken, removeToken } from '@/utils/auth';
import * as mutationTypes from 'constants/mutation-types';
import { login, getUserInfo } from '@/api';
import services from '@/services';
import { zip } from '@/utils/zip';
import { CustomMessageTypes } from '@/components/mixin/aim';

export default {
  login({ commit }, userInfo) {
    const { userId, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ userId, password })
        .then((res) => {
          const { token } = res;
          commit(mutationTypes.SET_TOKEN, token);
          setToken(token);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit(mutationTypes.SET_TOKEN, '');
      removeToken();
      resolve();
    });
  },

  getUserInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getUserInfo(state.token)
        .then((res) => {
          const { data } = res;

          if (!data) {
            reject('用户信息认证失败，请重新登录');
          }

          const {
            userId,
            // userName,
            userAvatar,
            role,
          } = data;

          const userName = 'LOP';

          if (role !== 'anchor') {
            reject('没有权限登录房间');
          }

          commit(mutationTypes.UPDATE_USER_INFO, {
            userId,
            userName,
            userAvatar,
          });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  logout({ dispatch }) {
    return new Promise((resolve) => {
      dispatch('resetToken').then(() => {
        location.reload();
      });
      resolve();
    });
  },

  setRoomIntroduce({ commit, state }, roomIntroduce) {
    return new Promise((resolve, reject) => {
      services.updateRoom({
        id: state.groupId,
        title: state.roomName,
        notice: zip(JSON.stringify(roomIntroduce)),
      }).then((res) => {
        commit(mutationTypes.UPDATE_ROOM_INTRODUCE, roomIntroduce);
        resolve(res.notice);
      })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setRoomName({ commit, state }, roomName) {
    return new Promise((resolve, reject) => {
      services.updateRoom({
        id: state.groupId,
        title: roomName,
      }).then((res) => {
        commit(mutationTypes.UPDATE_ROOM_NAME, roomName);
        resolve(res.title);
      })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateRoom({ commit, state }, { title = state.roomName, notice = state.roomIntroduce }) {
    const zipedNotice = zip(JSON.stringify(notice));
    return new Promise((resolve, reject) => {
      services.updateRoom({
        id: state.groupId,
        title,
        notice: zip(JSON.stringify(notice)),
      }).then((res) => {
        commit(mutationTypes.UPDATE_ROOM_NAME, title);
        window.AliyunInteractionInstance.sendMessageToGroup({
          groupId: state.groupId,
          type: CustomMessageTypes.NoticeUpdate,
          data: JSON.stringify({ content: zipedNotice }),
        });
        commit(mutationTypes.UPDATE_ROOM_INTRODUCE, notice);
        resolve(res);
      })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
