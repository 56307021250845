// 配置 APPServer 服务域名
export const ServicesOrigin = 'https://www.landofpromise.co:7001';

// 配置api接口路径前缀
export const ApiPrefixPath = '/api/v1/live/';

export const RequestBaseUrl = `${ServicesOrigin}${ApiPrefixPath}`;

// api名
export const ApiNames = {
  login: 'login',
  token: 'token',
  list: 'list',
  get: 'get',
  create: 'create',
  update: 'update',
};

export function getApiUrl(name) {
  return `${RequestBaseUrl}${name}`;
}
