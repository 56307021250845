<template lang="pug">
  div#app
    router-view
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="stylus">
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  --webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  width 100%
  height 100%
  position relative
  color $fontColor
</style>
