import request from '@/api/request';

export function login(data) {
  return request({
    url: '/api/user/login',
    method: 'post',
    data,
  });
}

export function getUserInfo(token) {
  return request({
    url: '/api/user/info',
    method: 'get',
    params: {
      token,
    },
  });
}

export function getRoomInfo() {
  return request({
    url: '/api/room/info',
    method: 'get',
  });
}
