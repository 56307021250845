import { decodeText } from '@/utils/decodeText';
import { mapState } from 'vuex';
import { SET_GROUP_ID, UPDATE_ROOM_INTRODUCE, UPDATE_ROOM_NAME } from 'constants/mutation-types';
import { unzip } from '@/utils/zip';
import { createEditor } from '@wangeditor/editor';
import services from '@/services';

export const CustomMessageTypes = {
  Comment: 10001, // 评论
  LiveStart: 10003, // 开始直播
  LiveStop: 10004, // 结束直播
  LiveInfo: 10005, // 直播间信息
  NoticeUpdate: 10006, // 公告更新
  ApplyRTC: 20001, // 申请连麦（观众发送、主播接收）
  RespondRTC: 20002, // 同意/拒绝 连麦申请（主播发送，观众接收）
  RTCStart: 20003, // 上麦通知
  RTCStop: 20004, // 下麦通知
  RTCKick: 20005, // 踢下麦（主播发送，观众接收）
};

export default {
  data() {
    return {
      aim: null,
      messageList: [], // 消息列表
    };
  },
  computed: {
    ...mapState({
      userInfo: 'userInfo',
      groupId: 'groupId',
      roomName: 'roomName',
      roomIntroduce: 'roomIntroduce',
      liveStage: 'liveStage',
    }),
  },
  methods: {
    // ------------ 初始化及销毁相关 ----------
    // 创建互动SDK实例
    async initAim() {
      const { InteractionEngine } = window.AliyunInteraction;
      this.aim = InteractionEngine.create();
      window.AliyunInteractionInstance = this.aim;
      await this.loginAim();
      await this.searchGroup();

      this.listenInteractionMessage();
    },
    async loginAim() {
      const { userId, userName } = this.userInfo;
      await services.login(userId, userName);
      // 先通过 Appserver 获取 SDK 所需的 token，请根据你实际情况更换
      // 注意：web 端调用 Appserver 的 token 接口时，参数 deviceType 需要是 'web'
      // 而 deviceId 可以通过静态方法 InteractionEngine.getDeviceId 获得，或者可以自行创建一个 uuid
      const tokenObj = await services.getToken();
      // 接口正确返回后，调用实例的 auth 方法建联
      await this.aim.auth(tokenObj.access_token);

      // 注意：若控制台上出现错误
      // "Failed to construct 'WebSocket': The URL's scheme must be either 'ws' or 'wss'. 'tls' is not allowed."
      // 请检查 deviceType 是否传的是 'web'
    },
    async searchGroup() {
      try {
        const roomList = await services.getRoomList(1, 20);
        if (!Array.isArray || !roomList.length) throw new Error('房间不存在');
        // 加入群组
        this.$store.commit(SET_GROUP_ID, roomList[0].chatId);
        console.log(roomList[0].chatId);
        this.joinGroup();
      } catch (imError) {
        // 不存在的情况
        console.warn('searchGroup error:', imError); // 搜素群组失败的相关信息
        await this.createGroup();
        await this.joinGroup();
      }
    },
    async createGroup() {
      try {
        const { chatId } = await services.createRoom(this.roomName);
        this.$store.commit(SET_GROUP_ID, chatId);
      } catch (imError) {
        console.error('createGroup error:', imError);
        this.$message.error('创建房间失败'); // 创建群组失败的相关信息
      }
    },
    async joinGroup() {
      try {
        await this.aim.joinGroup({
          groupId: this.groupId, // 消息组id
          userNick: this.userInfo.userName, // 用户昵称
          broadCastType: 2, // 系统消息扩散类型，0： 不扩散；1：扩散到指定人; 2：扩散到群组
          broadCastStatistics: true, // 是否扩散统计类消息
        });
        this.getGroupNotice();
      } catch (error) {
        console.error('joinGroup error:', error); // 申请加群失败的相关信息
      }
    },
    async getGroupNotice() {
      const { notice, title } = await services.getRoomDetail(this.groupId);
      if (notice) {
        const content = JSON.parse(unzip(notice));
        const editor = createEditor({ content });
        this.$store.commit(UPDATE_ROOM_INTRODUCE, editor.getHtml());
      }
      if (title) {
        this.$store.commit(UPDATE_ROOM_NAME, title);
      }
    },
    // 登出im
    async logout() {
      try {
        await this.aim.logout();
      } catch (imError) {
        console.error('logout error:', imError);
      }
    },
    // 监听 Interaction SDK 消息
    listenInteractionMessage() {
      const { InteractionEventNames = {} } = window.AliyunInteraction || {};
      this.aim.on(InteractionEventNames.Message, (eventData) => {
        console.log('收到信息啦', eventData);
        this.handleReceivedMessage(eventData || {});
      });
    },
    handleReceivedMessage(eventData) {
      const { InteractionMessageTypes = {} } = window.AliyunInteraction || {};
      const { type, data, messageId, senderId, senderInfo = {} } = eventData || {};
      const nickName = senderInfo.userNick || senderId;

      switch (type) {
        case CustomMessageTypes.Comment:
          // 接收到评论消息
          if (data && data.content) {
            this.addMessageItem(data.content, nickName, messageId);
          }
          break;
        case InteractionMessageTypes.PaaSLikeInfo:
          // 用户点赞数据
          break;
        case InteractionMessageTypes.PaaSUserJoin:
          // 用户加入聊天组，更新直播间统计数据
          this.handleUserJoined(nickName, senderId);
          break;
        case InteractionMessageTypes.PaaSUserLeave:
          // 用户离开聊天组，不需要展示
          break;
        case InteractionMessageTypes.PaaSMuteGroup:
          // 互动消息组被禁言
          break;
        case InteractionMessageTypes.PaaSCancelMuteGroup:
          // 互动消息组取消禁言
          break;
        case InteractionMessageTypes.PaaSMuteUser:
          // 个人被禁言
          break;
        case InteractionMessageTypes.PaaSCancelMuteUser:
          // 个人被取消禁言
          break;
        case CustomMessageTypes.LiveStart:
          // 开始直播
          break;
        case CustomMessageTypes.LiveStop:
          // 结束直播
          break;
        case CustomMessageTypes.LiveInfo:
          // 直播间信息更新
          break;
        case CustomMessageTypes.NoticeUpdate:
          // 公告更新
          break;
        default:
          break;
      }
    },
    addMessageItem(content, nickName) {
      this.messageList.push({
        nick: nickName,
        content,
        renderContent: decodeText(content),
      });
    },
    handleUserJoined(nickName, userId) {
      if (userId === this.userInfo.userId) return;

      const content = `${nickName}进入了直播间`;
      this.messageList.push({
        nick: content,
      });
    },
    // 发送消息
    async sendMessage(content) {
      const options = {
        groupId: this.groupId,
        type: CustomMessageTypes.Comment,
        data: JSON.stringify({ content }),
      };
      this.aim.sendMessageToGroup(options);
    },
  },
};
