import Vue from 'vue';
import VueRouter from 'vue-router';
import { getToken } from '@/utils/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/views/pusher.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const whiteList = ['/login'];

router.beforeEach((to, from, next) => {
  const hasToken = getToken();

  if (hasToken) {
    next();
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next('/login');
    }
  }
});

export default router;
